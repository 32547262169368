.grid-centered {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
}

.carousel-root {
  display: flex;
  flex-grow: 1;
  align-items: center;
  z-index: 1;
}

.carousel-root > * {
  padding-top: 7.5%;
  padding-bottom: 7.5%;
}

/* Small Screen */
@media screen and (max-width: 39.9375em) {
  .projects-subtitle {
    display: none;
  }
  .projects-title {
    text-decoration: underline;
    text-decoration-color: var(--color-green);
    font-size: 1.5em;
  }
  .projects-text {
    font-size: 1.2em;
  }
  .projects-indicator-dot {
    font-size: 0.75em;
  }
}

@media screen and (min-width: 40em) and (max-width: 74.9375em) or (max-height: 60em) {
  .projects-subtitle {
    display: none;
  }
  .projects-title {
    text-decoration: underline;
    text-decoration-color: var(--color-green);
    font-size: 1.5em;
  }
  .projects-text {
    font-size: 1.2em;
  }
  .projects-indicator-dot {
    font-size: 1em;
  }
}

/* Large and up */
@media screen and (min-width: 75em) {
  .projects-subtitle {
    font-style: italic;
  }
  .projects-title {
    text-decoration: underline;
    text-decoration-color: var(--color-green);
  }
  .projects-indicator-dot {
    font-size: 1.5em;
  }
}
